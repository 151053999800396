export default {

    version: '10012',
    language: 'de',

    googleApi: 'AIzaSyBoGEsyYZ30VYxAm7j0vQFS1kD43s-WhlM',

    token: null,
    userId: null,
    userGroupId: null,
    userIdent: null,
    dashboardReportId: null,

    menu: [],

    host: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://swb/' : 'https://swb.halvar-it.com/',
    apiHost: ((document.location.origin === 'http://localhost:8080') || (document.location.origin === 'http://localhost:8081')) ? 'http://swb/api/' : 'https://swb.halvar-it.com/api/'
};
