import auth from "./auth";
import { createRouter, createWebHashHistory } from "vue-router";

import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import appConfig from "@/app-config";

import {loadMessages, locale} from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import ylviMessages from "@/ylvi_lng.json";

function loadView(view) {
    return () => import (`./views/${view}.vue`);
}

function addRouteFromMenuItem(items) {

    items.forEach((item)=> {
        if (item.path && item.path !== '') {
            router.addRoute({
                component: loadView(item.path.replace('/','')),
                name: item.path.replace('/',''),
                path: item.path,
                meta: {
                    requiresAuth: true,
                    layout: defaultLayout,
                }
            });
        }
        if (item.items) addRouteFromMenuItem(item.items);
    });
}

loadMessages(deMessages);
loadMessages(ylviMessages);
locale(appConfig.language);

let version = localStorage.getItem('version');
if (version !== appConfig.version) {
    let token = localStorage.getItem('token')
    localStorage.clear();
    localStorage.setItem('token', token);
    localStorage.setItem('version', appConfig.version);
}

await auth.authenticate();

const home = 'home';

const router = new createRouter({
    routes: [
        {
            path: "/",
            redirect: home
        },

        {
            path: "/:pathMatch(.*)*",
            redirect: home
        },
        {
            path: "/home",
            name: "home",
            meta: {
                requiresAuth: true,
                layout: defaultLayout
            },
            component: loadView("swb/object-overview")
        },

        {
            path: "/authentication",
            name: "login-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Anmeldung"
            },
            component: loadView("authentication/login-form")
        },

        {
            path: "/createAccount",
            name: "create-account-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Zugang beantragen"
            },
            component: loadView("authentication/create-account/create-account-form")
        },
        {
            path: "/resetPassword",
            name: "reset-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort zurücksetzen"
            },
            component: loadView("authentication/reset-password/reset-password-form")
        },
        {
            path: "/changePassword",
            name: "change-password-form",
            meta: {
                requiresAuth: false,
                layout: simpleLayout,
                title: "Kennwort ändern"
            },
            component: loadView("authentication/change-password/change-password-form")
        },


    ],
    history: createWebHashHistory()
});

addRouteFromMenuItem(appConfig.menu);
router.beforeEach((to, from, next) => {

    let version = localStorage.getItem('version');
    if (version !== appConfig.version) {
        let token = localStorage.getItem('token')
        localStorage.clear();
        localStorage.setItem('token',token);
        localStorage.setItem('version',appConfig.version);
    }

    if (from.name === 'login-form')
        addRouteFromMenuItem(appConfig.menu);

    if (to.name === "login-form" && auth.loggedIn()) {
        next({name: "home"});
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!auth.loggedIn()) {
            next({
                name: "login-form",
                query: {redirect: to.fullPath}
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
