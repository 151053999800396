<template>
    <div
        class="dx-swatch-additional side-navigation-menu"
        @click="forwardClick"
    >
        <slot/>
        <div class="menu-container">
            <DxTreeView
                ref="treeViewRef"
                v-model:items="menu"
                expanded-expr="isCollapsed"
                key-expr="path"
                selection-mode="single"
                expand-event="click"
                @item-click="handleItemClick"
                :search-enabled="isLargeScreen && !compactMode"
                :search-expr="['text', 'origin']"
                width="100%"
            />
        </div>
    </div>
</template>

<script>

import DxTreeView from 'devextreme-vue/tree-view';
import {sizes} from '@/utils/media-query';
import {onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import appConfig from "@/app-config";


export default {

    props: {
        compactMode: Boolean
    },

    computed: {
      menu() {
          return appConfig.menu;
      }
    },

    setup(props, context) {
        const route = useRoute();
        const router = useRouter();

        const isLargeScreen = sizes()['screen-large'];
        const treeViewRef = ref(null);

        let items = appConfig.menu;

        function forwardClick(...args) {
            context.emit("click", args);
        }

        function handleItemClick(e) {
            if (!e.itemData.path) {
                return;
            }
            router.push(e.itemData.path);

            const pointerEvent = e.event;
            pointerEvent.stopPropagation();
        }

        function updateSelection() {
            if (!treeViewRef.value || !treeViewRef.value.instance) {
                return;
            }

            treeViewRef.value.instance.selectItem(route.path);
            treeViewRef.value.instance.expandItem(route.path);
        }

        onMounted(() => {
            updateSelection();
            if (props.compactMode) {
                treeViewRef.value.instance.collapseAll();
            }
        });


        watch(
            () => route.path,
            () => {
                updateSelection();
            }
        );

        watch(
            () => props.compactMode,
            () => {
                if (props.compactMode) {
                    treeViewRef.value.instance.collapseAll();
                } else {
                    updateSelection();
                }
            }
        );

        return {
            treeViewRef,
            items,
            forwardClick,
            handleItemClick,
            isLargeScreen
        };
    },
    components: {
        DxTreeView,
    }
};
</script>

<style lang="scss">
@import "../dx-styles.scss";
@import "../themes/generated/variables.additional.scss";

.side-navigation-menu {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    width: 250px !important;

    .menu-container {
        min-height: 100%;
        display: flex;
        flex: 1;

        .dx-treeview {
            // ## Long text positioning
            white-space: nowrap;
            // ##

            // ## Icon width customization
            .dx-treeview-item {
                padding-left: 0;
                padding-right: 0;

                .dx-icon {
                    width: $side-panel-min-width !important;
                    margin: 0 !important;
                    height: 28px;
                }
            }

            .dx-treeview-item {
                display: block;
                cursor: pointer;
                padding: 8px 8px 10px 0;
                min-height: 22px;
                line-height: 24px;
            }

            // ##

            // ## Arrow customization
            .dx-treeview-node {
                padding: 0 0 !important;
            }

            .dx-treeview-toggle-item-visibility {
                right: 10px;
                left: auto;
            }

            .dx-rtl .dx-treeview-toggle-item-visibility {
                left: 10px;
                right: auto;
            }

            // ##

            // ## Item levels customization
            .dx-treeview-node {
                &[aria-level="1"] {
                    font-weight: bold;
                    border-bottom: 1px solid $base-border-color;
                }

                &[aria-level="2"] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 15px;
                }

                &[aria-level="3"] .dx-treeview-item-content {
                    font-weight: normal;
                    padding: 0 30px;
                }
            }

            // ##
        }

        // ## Selected & Focuced items customization
        .dx-treeview {
            .dx-treeview-node-container {
                .dx-treeview-node {
                    &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
                        background: #0D1B24;
                    }

                    &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
                        background-color: lighten($base-bg, 4);
                    }
                }
            }
        }

        .dx-theme-generic .dx-treeview {
            .dx-treeview-node-container
            .dx-treeview-node.dx-state-selected.dx-state-focused
            > .dx-treeview-item
            * {
                color: inherit;
            }
        }
    }

}
</style>
